import React, { FC, useContext, useRef } from "react";
import { graphql } from "gatsby";
import { WavySection } from "../components/wavy-section";
import {
  colors,
  ySpaceRem,
  xSpaceRem,
  fontStacks,
  targetBaseFontSizePx,
} from "../global-style";
import { LayoutContext } from "../components/layout";
import { Center } from "../components/center";
import { AboutUsPageQuery } from "../../graphql-types";
import { Footer } from "../components/footer";
import { css } from "styled-components";
import Img from "gatsby-image";
import { SEO } from "../components/seo";

export const query = graphql`
  query AboutUsPage {
    wp {
      page(id: "over-ons/", idType: URI) {
        seo {
          ...SEO
        }
      }

      partners(where: {orderby: {order: ASC, field: MENU_ORDER}}) {
        edges {
          node {
            id
            title
            partnerCustomFields {
              type
              websiteUrl
              logo {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 600) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const AboutUsPage: FC<{ data: AboutUsPageQuery }> = props => {
  const { remInPx, vwInPx, waveClearance } = useContext(LayoutContext);
  const pageTitleRef = useRef<HTMLHeadingElement>(null);

  return (
    <>
      <SEO {...props.data.wp.page?.seo} />

      <WavySection
        backgroundColor={colors.mediumBlue}
        style={{
          color: colors.white,
          paddingTop: `calc(${waveClearance} + ${2 * ySpaceRem}rem)`,
          paddingBottom: `${ySpaceRem}rem`,
          transition: "padding 0.25s ease",
        }}
      >
        <Center>
          <div
            style={{
              ...(pageTitleRef.current &&
                (() => {
                  const paddingYRem =
                    ySpaceRem / 2 -
                    ((pageTitleRef.current.getBoundingClientRect().height /
                      remInPx) %
                      (ySpaceRem / 2));

                  return {
                    paddingTop: `${0.5 * paddingYRem}rem`,
                    paddingBottom: `${0.5 * paddingYRem}rem`,
                  };
                })()),
              transition: "padding 0.25s ease",
            }}
          >
            <h1
              ref={pageTitleRef}
              style={{
                fontFamily: fontStacks.cubano,
              }}
              css={(() => {
                const minFontSizeRem = 2;
                const maxFontSizeRem = 2.8888;
                const vwRangeStartPx = 500;

                return css`
                  font-size: ${(minFontSizeRem *
                    (remInPx || targetBaseFontSizePx)) /
                    (vwRangeStartPx / 100)}vw;
                  line-height: 1;

                  @media (max-width: ${vwRangeStartPx}px) {
                    font-size: ${minFontSizeRem}rem;
                  }

                  @media (min-width: ${vwRangeStartPx *
                      (maxFontSizeRem / minFontSizeRem)}px) {
                    font-size: ${maxFontSizeRem}rem;
                  }
                `;
              })()}
            >
              Over ons
            </h1>
          </div>
        </Center>
      </WavySection>

      <div
        style={{
          paddingTop: `${1.5 * ySpaceRem}rem`,
          paddingBottom: `calc(${1.5 * ySpaceRem}rem + ${waveClearance})`,
        }}
      >
        <Center>
          <div style={{ maxWidth: `${32 * xSpaceRem}rem` }}>
            <div
              style={{
                fontSize: "1.1rem",
                marginBottom: `${2 * ySpaceRem}rem`,
              }}
            >
              <p style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
                De campagne ‘Laat u niet verrassen’ is een initiatief van de
                gemeenten Hardenberg, Ommen, Emmen, Coevorden en Borger-Odoorn,
                samen met de waterschappen Vechtstromen, Hunze &amp; Aa's en WMD, en
                de provincies Overijssel en Drenthe.
              </p>

              <p style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
                We krijgen steeds vaker te maken met extremen, zoals hittegolven
                en hevige neerslag. Dit heeft effect op onze leefomgeving. Samen
                hebben we deze klimaateffecten in kaart gebracht. Nu is het tijd
                om te bekijken en bespreken waar we risico’s lopen en welke
                maatregelen we samen kunnen nemen. Zodat we beter zijn
                voorbereid op extreem weer. We vinden het belangrijk om daarvoor
                met alle betrokkenen in gesprek te gaan. Door ons slim aan te
                passen aan de klimaatverandering zorgen we ervoor dat onze regio
                klimaatbestendig wordt.
              </p>

              <p style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
                Het is goed te weten dat gemeenten, waterschap en provincies al
                allerlei maatregelen nemen om overlast te voorkomen. Zij kunnen
                dit echter niet alleen oplossen. Alleen met uw hulp maken we het
                verschil!
              </p>
            </div>

            <h2 style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>Gemeenten</h2>
            <p style={{ marginBottom: `${ySpaceRem}rem` }}>
              Binnen de gemeenten wordt al aan verschillende klimaatprojecten
              gewerkt. Meer informatie hierover vindt u op website van uw eigen
              gemeente. Ook als u vragen heeft over het project ‘Laat u niet
              verrassen’ of over de effectenkaarten, kunt u bij uw eigen
              gemeente terecht.
            </p>
            <div style={{ marginBottom: `${2 * ySpaceRem}rem` }}>
              <Partners
                partners={props.data.wp.partners?.edges?.filter(
                  edge =>
                    edge?.node?.partnerCustomFields?.type === "municipality"
                )}
              />
            </div>

            <h2 style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
              Provincies
            </h2>
            <p style={{ marginBottom: `${ySpaceRem}rem` }}>
              Binnen de provincies wordt al aan verschillende klimaatprojecten
              gewerkt. Meer informatie hierover vindt u op website van uw eigen
              provincie.
            </p>
            <div style={{ marginBottom: `${2 * ySpaceRem}rem` }}>
              <Partners
                partners={props.data.wp.partners?.edges?.filter(
                  edge => edge?.node?.partnerCustomFields?.type === "province"
                )}
              />
            </div>

            <h2 style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
              Waterschappen
            </h2>
            <p style={{ marginBottom: `${ySpaceRem}rem` }}>
              Binnen de waterschappen wordt al aan verschillende
              klimaatprojecten gewerkt. Meer informatie hierover vindt u op
              website van uw eigen waterschap.
            </p>
            <div>
              <Partners
                partners={props.data.wp.partners?.edges?.filter(
                  edge =>
                    edge?.node?.partnerCustomFields?.type === "water_authority"
                )}
              />
            </div>
          </div>
        </Center>
      </div>

      <Footer backgroundColor={colors.lightestGrey} />
    </>
  );
};

export default AboutUsPage;

const Partners: FC<{
  partners: any;
}> = ({ partners }) => {
  return (
    <div
      css={`
        & > * + * {
          margin-top: ${ySpaceRem}rem;
        }
      `}
    >
      {partners.map((edge, i) => (
        <div
          key={i}
          style={{
            border: `${ySpaceRem}rem solid ${colors.lightestGrey}`,
            padding: `${ySpaceRem}rem`,
          }}
        >
          <div style={{ overflow: "hidden" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                marginLeft: `${-0.5 * xSpaceRem}rem`,
                marginRight: `${-0.5 * xSpaceRem}rem`,
                marginTop: `${-0.5 * ySpaceRem}rem`,
                marginBottom: `${-0.5 * ySpaceRem}rem`,
              }}
            >
              <div
                style={{
                  flexBasis: "15rem",
                  flexGrow: 1,
                  marginLeft: `${0.5 * xSpaceRem}rem`,
                  marginRight: `${0.5 * xSpaceRem}rem`,
                  marginTop: `${0.5 * ySpaceRem}rem`,
                  marginBottom: `${0.5 * ySpaceRem}rem`,
                }}
              >
                <Img
                  style={{
                    width: "80%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  fluid={
                    edge.node.partnerCustomFields.logo.localFile.childImageSharp
                      .fluid
                  }
                />
              </div>
              <div
                style={{
                  flexBasis: "15rem",
                  flexGrow: 1,
                  marginLeft: `${0.5 * xSpaceRem}rem`,
                  marginRight: `${0.5 * xSpaceRem}rem`,
                  marginTop: `${0.5 * ySpaceRem}rem`,
                  marginBottom: `${0.5 * ySpaceRem}rem`,
                }}
              >
                <h3
                  dangerouslySetInnerHTML={{ __html: edge.node.title || "" }}
                />
                <a
                  href={edge.node.partnerCustomFields.websiteUrl}
                  target="_blank"
                  style={{
                    color: colors.mediumBlue,
                    textDecoration: "underline",
                  }}
                  title={`Bezoek de website van ${edge.node.title}`}
                >
                  Bezoek de website
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
